/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  background-color: ${colors.white};
  border-radius: 5px;
`;

const Content = Styled.View`
  padding: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Indicator = Styled.Loading`
`;

const LoadingModal = ({loading, title}: any) => {
  return (
    <Modal.Content isOpen={loading} onClose={() => {}}>
      <Container pointerEvents={'box-none'}>
        <Wrapper>
          <Content>
            <Title>{title}</Title>
            <Space height={12} />
            <Indicator size={'large'} color={colors.gray.secondary} />
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

export default LoadingModal;
