/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {useModal, useNavigation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

// App
// @ts-expect-error TS(2307): Cannot find module 'config' or its corresponding t... Remove this comment to see the full error message
import {cache} from 'config';
import LogOutModal from 'modules/App/components/LogOutModal';
import Sidebar from 'modules/App/components/Sidebar';

const LogOutSidebarItem = () => {
  const {navigator} = useNavigation();
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <Sidebar.Item icon={Icon.SignOut} text={'Log Out'} onPress={handleOpen} />
      <LogOutModal
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={async () => {
          await cache.reset();
          await Storage.clear();
          (navigator as any).popToTop();
          navigator.navigate('Start');
        }}
      />
    </React.Fragment>
  );
};

export default LogOutSidebarItem;
