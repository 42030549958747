/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import SyncItemFormSummariesList from 'modules/Item/components/SyncItemFormSummariesList';
import SyncItemFormsHeader from 'modules/Item/components/SyncItemFormsHeader';

const Container = Styled.View`
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Content = Styled.View`
  padding-horizontal: 6px;
`;

const Touchable = Styled.Touchable`
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  margin-horizontal: 10px;
  flex: 1;
`;

const EmptySubtitle = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: center;
  padding-bottom: 12px;
`;

const SyncInventoryFormCartonSummaryItem = ({syncCategoryForm, syncItemForms}: any) => {
  const takeSyncItemForms = syncItemForms.filter((syncItemForm: any) => {
    return SyncItemForm.getTakeCount(syncItemForm) > 0;
  });
  const leaveSyncItemForms = syncItemForms.filter((syncItemForm: any) => {
    return SyncItemForm.getLeaveCount(syncItemForm) > 0;
  });
  const hasTakeCartons = takeSyncItemForms.length > 0;
  const hasLeaveCartons = leaveSyncItemForms.length > 0;
  const [isExpanded, setIsExpanded] = useState(hasTakeCartons || hasLeaveCartons);

  return (
    <Container>
      <Header>
        <Name>{syncCategoryForm.name}</Name>
        <Space width={10} />
        <SyncItemFormsHeader syncItemForms={syncItemForms} />
        <Space width={20} />
        <Touchable onPress={() => setIsExpanded(!isExpanded)}>
          <Icon
            color={colors.gray.primary}
            size={14}
            source={isExpanded ? Icon.ChevronDown : Icon.ChevronUp}
          />
        </Touchable>
      </Header>
      {isExpanded && (
        <React.Fragment>
          <Line />
          <Content>
            <Space height={12} />
            <SyncItemFormSummariesList
              listKey={`TAKE-CARTONS-${syncCategoryForm.categoryId}`}
              kind={'TAKE'}
              syncItemForms={takeSyncItemForms}
            />
            {hasTakeCartons && hasLeaveCartons && (
              <React.Fragment>
                <Space height={3} />
                <DashedLine />
                <Space height={8} />
              </React.Fragment>
            )}
            <SyncItemFormSummariesList
              listKey={`LEAVE-CARTONS-${syncCategoryForm.categoryId}`}
              kind={'LEAVE'}
              syncItemForms={leaveSyncItemForms}
            />
            {hasTakeCartons || hasLeaveCartons ? (
              <Space height={7} />
            ) : (
              <EmptySubtitle>No items added</EmptySubtitle>
            )}
          </Content>
        </React.Fragment>
      )}
    </Container>
  );
};

export default SyncInventoryFormCartonSummaryItem;
