/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import SyncItemFormItem from 'modules/Item/components/SyncItemFormItem';

const Container = Styled.View`
  flex-wrap: wrap;
`;

const getFilteredSyncItemForms = ({selectedCategoryId, syncItemForms}: any) => {
  // This is the "surveyed" category that should show all non-zero item forms.
  if (!selectedCategoryId) {
    return syncItemForms.filter((syncItemForm: any) => {
      return syncItemForm.takeCount > 0 || syncItemForm.leaveCount > 0;
    });
  }

  return syncItemForms.filter((syncItemForm: any) => {
    return syncItemForm.categoryIds.includes(selectedCategoryId);
  });
};

const getFilteredItemUuids = ({selectedCategoryId, syncItemForms}: any) => {
  const filteredSyncItemForms = getFilteredSyncItemForms({selectedCategoryId, syncItemForms});
  return filteredSyncItemForms.map((syncItemForm: any) => syncItemForm.uuid);
};

const SyncRoomFormItemsList = ({syncRoomField, syncRoomForm, form}: any) => {
  const selectedCategoryId = _.toString(syncRoomForm.selectedCategoryId);
  const {syncItemForms} = syncRoomForm.syncCollectionForm;
  const itemUuids = getFilteredItemUuids({selectedCategoryId, syncItemForms});

  return (
    <Container>
      {syncItemForms.map((syncItemForm: any, index: any) => {
        // We always map over all syncItemForms to get the correct index but we only render the ones
        // that are included in the current filters.
        if (!itemUuids.includes(syncItemForm.uuid)) {
          return null;
        }

        return (
          <SyncItemFormItem
            key={index}
            syncItemField={`${syncRoomField}.syncCollectionForm.syncItemForms.${index}`}
            syncItemForm={syncItemForm}
            syncRoomField={syncRoomField}
            form={form}
          />
        );
      })}
    </Container>
  );
};

export default SyncRoomFormItemsList;
