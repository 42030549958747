/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import SyncItemFormsHeader from 'modules/Item/components/SyncItemFormsHeader';
import JobSidebar from 'modules/Job/components/JobSidebar';
import DeleteRoomModal from 'modules/Room/Show/components/DeleteRoomModal';
import EditRoomModal from 'modules/Room/Show/components/EditRoomModal';
import SyncRoomCollectionItemsList from 'modules/Room/Show/components/SyncRoomCollectionItemsList';

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const HeaderInfo = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const HeaderTitle = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Touchable = Styled.Touchable`
`;

const ShowRoomHeader = ({jobUuid, syncRoomField, syncRoomForm, syncInventoryForm, form}: any) => {
  const {navigator} = useNavigation();
  const editRoomModal = useModal({name: 'EditRoomModal'});
  const deleteRoomModal = useModal({name: 'DeleteRoomModal'});

  // On edit, we override the existing syncRoomForm with the new one.
  const handleEditRoom = async ({syncRoomForm}: any) => {
    form.setFieldValue(syncRoomField, syncRoomForm);
    await form.submitForm();
    editRoomModal.handleClose();
  };

  // On delete, we override the existing syncRoomForm with the new one (isDeleted=True).
  const handleDeleteRoom = async ({syncRoomForm}: any) => {
    form.setFieldValue(syncRoomField, syncRoomForm);
    await form.submitForm();
    navigator.navigate('InventoryShowJob', {jobUuid});
  };

  return (
    <React.Fragment>
      <Header>
        <HeaderInfo>
          <HeaderTitle numberOfLines={1}>{syncRoomForm.name}</HeaderTitle>
          <Space width={24} />
          <Touchable onPress={editRoomModal.handleOpen}>
            <Icon color={colors.gray.primary} size={14} source={Icon.EllipsisH} />
          </Touchable>
        </HeaderInfo>
        <SyncItemFormsHeader syncItemForms={syncRoomForm.syncCollectionForm.syncItemForms} />
      </Header>
      <EditRoomModal
        key={editRoomModal.key}
        isOpen={editRoomModal.isOpen}
        syncRoomForm={syncRoomForm}
        handleOpen={editRoomModal.handleOpen}
        handleClose={editRoomModal.handleClose}
        handleSubmit={handleEditRoom}
        handleDelete={deleteRoomModal.handleOpen}
      />
      <DeleteRoomModal
        key={deleteRoomModal.key}
        isOpen={deleteRoomModal.isOpen}
        syncRoomForm={syncRoomForm}
        handleClose={deleteRoomModal.handleClose}
        handleCancel={() => {
          deleteRoomModal.handleClose();
          editRoomModal.handleOpen();
        }}
        handleSubmit={handleDeleteRoom}
      />
    </React.Fragment>
  );
};

const Content = Styled.View`
  flex: 1;
`;

const ShowRoomPageContent = ({jobUuid, roomUuid, job}: any) => {
  const inventoryUuid = job.inventory.uuid;
  const {form} = useCurrentSyncInventoryForm({inventoryUuid});
  const {syncInventoryForm} = form.values;

  // Finds the current Room that we're displaying.
  const index = _.findIndex(syncInventoryForm.syncRoomForms, (syncRoomForm) => {
    return (syncRoomForm as any).uuid === roomUuid;
  });

  const syncRoomField = `syncInventoryForm.syncRoomForms.${index}`;
  const syncRoomForm = _.get(form.values, syncRoomField);

  if (!syncRoomForm) {
    return null;
  }

  return (
    <Content>
      <ShowRoomHeader
        jobUuid={jobUuid}
        syncRoomField={syncRoomField}
        syncRoomForm={syncRoomForm}
        syncInventoryForm={syncInventoryForm}
        form={form}
      />
      <SyncRoomCollectionItemsList
        syncRoomForm={syncRoomForm}
        syncRoomField={syncRoomField}
        form={form}
      />
    </Content>
  );
};

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.border};
`;

const ShowRoomPage = () => {
  const {params} = useNavigation();
  const {jobUuid, roomUuid} = params;
  const {data} = useQuery(ShowRoomPage.query, {
    fetchPolicy: 'cache-only',
    variables: {
      jobUuid,
    },
  });

  return (
    <Container>
      <JobSidebar selected={'ShowRoom'} jobUuid={jobUuid} roomUuid={roomUuid} />
      {!!data && (
        <ShowRoomPageContent key={roomUuid} jobUuid={jobUuid} roomUuid={roomUuid} job={data.job} />
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowRoomPage.query = gql`
  query ShowRoomPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      inventory {
        id
        uuid
      }
    }
  }
`;

export default ShowRoomPage;
