/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';

// App
import SyncRoomFormCategoriesList from 'modules/Room/Show/components/SyncRoomFormCategoriesList';
import SyncRoomFormItemsList from 'modules/Room/Show/components/SyncRoomFormItemsList';

const Container = Styled.View`
  flex: 1;
`;

const SyncRoomCollectionItemsList = ({syncRoomForm, syncRoomField, form}: any) => {
  return (
    <Container>
      <SyncRoomFormCategoriesList
        syncRoomField={syncRoomField}
        syncRoomForm={syncRoomForm}
        form={form}
        setSelectedCategoryId={(selectedCategoryId: any) => {
          form.setFieldValue(`${syncRoomField}.selectedCategoryId`, selectedCategoryId);
          setTimeout(() => form.submitForm(), 0);
        }}
      />
      <ScrollView horizontal style={{flex: 1}}>
        <SyncRoomFormItemsList
          syncRoomForm={syncRoomForm}
          syncRoomField={syncRoomField}
          form={form}
        />
      </ScrollView>
    </Container>
  );
};

export default SyncRoomCollectionItemsList;
