/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useForm, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import SyncRoomForm from '@shared/modules/Inventory/forms/SyncRoomForm';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  padding-vertical: 20px;
`;

const Content = Styled.View`
  padding-horizontal: 20px;
`;

const Actions = Styled.View`
  align-items: stretch;
  padding-horizontal: 20px;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const NewCustomRoomModal = ({isOpen, syncInventoryForm, handleClose, handleSubmit}: any) => {
  const responsive = useResponsive();
  const syncRoomForm = SyncRoomForm.new({
    inventoryId: syncInventoryForm.inventoryId,
    roomTypeId: null,
    name: '',
    syncItemForms: syncInventoryForm.syncItemTypeForms.map((syncItemTypeForm: any) => {
      return SyncItemForm.newForSyncItemTypeForm(syncItemTypeForm);
    }),
    primaryCategoryId: null,
  });

  const form = useForm({
    initialValues: {
      syncRoomForm: SyncRoomForm.toForm(syncRoomForm),
    },
    validate: ({syncRoomForm}) => SyncRoomForm.validate({prefix: 'syncRoomForm', syncRoomForm}),
    onSubmit: ({syncRoomForm}) => handleSubmit({syncRoomForm}),
  });

  return (
    <Modal
      isOpen={isOpen}
      title={'Custom Room'}
      subtitle={'Edit room details.'}
      onClose={handleClose}
    >
      <Container>
        <Content>
          <Field
            {...form}
            name={'syncRoomForm.name'}
            label={'Room Name'}
            input={{
              autoFocus: true,
              required: !form.values.syncRoomForm.name,
              placeholder: 'Enter a custom room name',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'syncRoomForm.floorNumber'}
            label={'Floor'}
            input={{
              placeholder: '#',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
          <Space height={20} />
          <Field
            {...form}
            name={'syncRoomForm.description'}
            label={'Additional Room Notes'}
            input={{
              placeholder: 'Enter additional notes',
              style: {
                width: responsive.mobile ? '100%' : 360,
              },
            }}
            style={{
              width: responsive.mobile ? '100%' : 360,
            }}
          />
        </Content>
        <Space height={20} />
        <Actions>
          <Button color={colors.blue.interactive} onPress={() => form.submitForm()}>
            <Text color={colors.white}>Add</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

export default NewCustomRoomModal;
