/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import {useForm, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import Modal from 'components/Modal';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
  padding: 20px;
`;

const CountRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const CounterTouchable = Styled.Touchable`
`;

const CounterButton = ({disabled, count, onPress, children}: any) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <Value style={{width: 40, height: 40}}>{children}</Value>
    </CounterTouchable>
  );
};

const Value = Styled.View`
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${(props) => (props as any).color};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const CounterTextInput = Styled.TextInput.H7`
`;

const CounterInput = ({name, count, form}: any) => {
  const disabled = count === 0;
  const onDecrement = () => form.setFieldValue(name, Float.toForm(count - 1));
  const onIncrement = () => form.setFieldValue(name, Float.toForm(count + 1));

  return (
    <Row>
      <CounterButton disabled={disabled} onPress={onDecrement}>
        <Icon
          color={disabled ? colors.gray.tertiary : colors.gray.primary}
          size={16}
          source={Icon.Minus}
        />
      </CounterButton>
      <CounterTextInput
        keyboardType={'phone-pad'}
        value={_.get(form, `values.${name}`)}
        placeholder={'Count'}
        onChangeText={(value: any) => form.setFieldValue(name, value)}
        style={{
          width: 80,
          height: 40,
          borderRadius: 0,
          borderColor: colors.gray.border,
          borderRightWidth: 0,
          borderLeftWidth: 0,
          textAlign: 'center',
        }}
      />
      <CounterButton onPress={onIncrement}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Plus} />
      </CounterButton>
    </Row>
  );
};

const Buttons = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Label = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CounterNumberButton = ({disabled, count, onPress}: any) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <CounterValue
        color={disabled ? colors.gray.background : colors.white}
        value={count}
        width={60}
      />
    </CounterTouchable>
  );
};

const ValueText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CounterValue = ({color, value, width}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Value color={color} style={{width, height: 35}}>
      <ValueText>{value}</ValueText>
    </Value>
  );
};

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const Touchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const IsDerivedWeightButton = ({syncInventoryForm, form}: any) => {
  const {isDerivedWeight} = form.values.syncItemForm;
  const densityFactor = Float.toFloat(form.values.syncItemForm.densityFactor);
  const volume = Float.toFloat(form.values.syncItemForm.volume);

  return (
    <Touchable
      // @ts-expect-error TS(2769): No overload matches this call.
      color={isDerivedWeight ? colors.blue.accent : colors.red.accent}
      onPress={() => {
        const newIsDerivedWeight = !isDerivedWeight;
        const newDensityFactor = newIsDerivedWeight
          ? syncInventoryForm.densityFactor
          : densityFactor;
        const newWeight = volume * newDensityFactor;
        form.setFieldValue('syncItemForm.isDerivedWeight', newIsDerivedWeight);

        if (newIsDerivedWeight) {
          form.setFieldValue('syncItemForm.densityFactor', Float.toForm(newDensityFactor));
          form.setFieldValue('syncItemForm.weight', Float.toForm(newWeight));
        }
      }}
    >
      <Icon
        color={isDerivedWeight ? colors.blue.accentDark : colors.red.warning}
        size={14}
        source={isDerivedWeight ? Icon.Link : Icon.Unlink}
      />
    </Touchable>
  );
};

const NewItemModalContent = ({syncItemForm, syncInventoryForm, onSubmit}: any) => {
  const responsive = useResponsive();
  const form = useForm({
    initialValues: {
      syncItemForm: SyncItemForm.toForm(syncItemForm),
    },
    validate: ({syncItemForm}) => SyncItemForm.validate({prefix: 'syncItemForm', syncItemForm}),
    onSubmit,
  });

  const {isDerivedWeight} = form.values.syncItemForm;
  const takeCount = Float.toFloat(form.values.syncItemForm.takeCount);
  const leaveCount = Float.toFloat(form.values.syncItemForm.leaveCount);
  const volume = Float.toFloat(form.values.syncItemForm.volume);
  const densityFactor = Float.toFloat(form.values.syncItemForm.densityFactor);
  const weight = Float.toFloat(form.values.syncItemForm.weight);
  const volumeIncrement = 5;
  const densityFactorIncrement = 0.25;

  return (
    <Container>
      <Field
        {...form}
        name={'syncItemForm.name'}
        label={'Item Name'}
        input={{
          autoFocus: true,
          required: !form.values.syncItemForm.name,
          placeholder: 'Enter a custom item name',
          style: {
            width: responsive.mobile ? '100%' : 360,
          },
        }}
        style={{
          width: responsive.mobile ? '100%' : 360,
        }}
      />
      <Space height={20} />
      <Row>
        <Label>CU FT</Label>
        <Buttons>
          <CounterNumberButton
            count={`-${volumeIncrement}`}
            onPress={() => {
              const newVolume = volume - volumeIncrement;
              const newWeight = newVolume * densityFactor;
              form.setFieldValue(`syncItemForm.volume`, newVolume);
              form.setFieldValue(`syncItemForm.weight`, newWeight);
            }}
          />
          <CounterValue color={colors.gray.border} value={volume} width={100} />
          <CounterNumberButton
            count={`+${volumeIncrement}`}
            onPress={() => {
              const newVolume = volume + volumeIncrement;
              const newWeight = newVolume * densityFactor;
              form.setFieldValue(`syncItemForm.volume`, newVolume);
              form.setFieldValue(`syncItemForm.weight`, newWeight);
            }}
          />
        </Buttons>
      </Row>
      <Space height={20} />
      <Row>
        <Label>LB / CU FT</Label>
        <Buttons>
          <IsDerivedWeightButton syncInventoryForm={syncInventoryForm} form={form} />
          <Space width={5} />
          <CounterNumberButton
            disabled={isDerivedWeight}
            count={`-${densityFactorIncrement}`}
            onPress={() => {
              const newDensityFactor = densityFactor - densityFactorIncrement;
              const newWeight = volume * newDensityFactor;
              form.setFieldValue(`syncItemForm.densityFactor`, newDensityFactor);
              form.setFieldValue(`syncItemForm.weight`, newWeight);
            }}
          />
          <CounterValue color={colors.gray.border} value={`${densityFactor}x`} width={100} />
          <CounterNumberButton
            disabled={isDerivedWeight}
            count={`+${densityFactorIncrement}`}
            onPress={() => {
              const newDensityFactor = densityFactor + densityFactorIncrement;
              const newWeight = volume * newDensityFactor;
              form.setFieldValue(`syncItemForm.densityFactor`, newDensityFactor);
              form.setFieldValue(`syncItemForm.weight`, newWeight);
            }}
          />
        </Buttons>
      </Row>
      <Space height={20} />
      <Row>
        <Label>LB</Label>
        <Buttons>
          <Space width={60} />
          <CounterValue color={colors.gray.border} value={weight} width={100} />
          <Space width={60} />
        </Buttons>
      </Row>
      {syncInventoryForm.isEnabledInventoryPriceField && (
        <React.Fragment>
          <Space height={20} />
          <Row>
            <Label>Price</Label>
            <Buttons>
              <Field
                {...form}
                name={'syncItemForm.price'}
                label={''}
                component={CurrencyInput}
                input={{
                  component: TextInput,
                  placeholder: 'Enter the price',
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  style: {
                    width: 180,
                  },
                }}
              />
            </Buttons>
          </Row>
        </React.Fragment>
      )}
      <Space height={20} />
      <Line />
      <Space height={10} />
      <CountRow>
        <Name>Going</Name>
        <CounterInput name={`syncItemForm.takeCount`} count={takeCount} form={form} />
      </CountRow>
      <CountRow>
        <Name>Not Going</Name>
        <CounterInput name={`syncItemForm.leaveCount`} count={leaveCount} form={form} />
      </CountRow>
      <Space height={20} />
      <Actions>
        <Button
          disabled={takeCount === 0 && leaveCount === 0}
          color={colors.blue.interactive}
          onPress={() => form.submitForm()}
        >
          <Text color={colors.white}>Add Custom Item</Text>
        </Button>
      </Actions>
    </Container>
  );
};

const NewItemModal = ({
  isOpen,
  name,
  syncRoomField,
  makeSyncItemForm,
  form,
  handleClose,
  setHasUnsavedChanges,
}: any) => {
  return (
    <Modal isOpen={isOpen} title={name} subtitle={''} onClose={handleClose}>
      {isOpen && (
        <NewItemModalContent
          syncItemForm={makeSyncItemForm()}
          syncInventoryForm={form.values.syncInventoryForm}
          onSubmit={({syncItemForm}: any) => {
            const syncItemFormsField = `${syncRoomField}.syncCollectionForm.syncItemForms`;
            const syncItemForms = _.get(form.values, syncItemFormsField);
            const index = syncItemForms.length;

            // Add the new SyncItemForm to the collection and submit the parent form.
            form.setFieldValue(`${syncItemFormsField}.${index}`, syncItemForm);
            form.submitForm();
            setHasUnsavedChanges(true);

            // Close the modal.
            handleClose();
          }}
        />
      )}
    </Modal>
  );
};

export default NewItemModal;
