/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncInventoryForm from '@shared/modules/Inventory/forms/SyncInventoryForm';
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import SyncInventoryFormCartonSummaryItem from 'modules/Job/Show/Inventory/components/SyncInventoryFormCartonSummaryItem';

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const getHasCarton = ({syncInventoryForm}: any) => {
  const syncItemForms = SyncInventoryForm.getAllSyncItemForms(syncInventoryForm);
  const categorySyncItemForms = syncItemForms.filter((syncItemForm) => {
    return (syncItemForm as any).kind === 'CARTON';
  });
  const totalTakeCount = _.sumBy(categorySyncItemForms, (syncItemForm) => {
    return SyncItemForm.getTakeCount(syncItemForm);
  });
  return totalTakeCount > 0;
};

const getSyncItemForms = ({syncInventoryForm, syncCategoryForm}: any) => {
  const syncItemForms = SyncInventoryForm.getAllSyncItemForms(syncInventoryForm);
  return syncItemForms.filter((syncItemForm) => {
    return (syncItemForm as any).categoryIds.includes(syncCategoryForm.categoryId);
  });
};

const SyncInventoryFormCartonSummariesList = ({syncInventoryForm}: any) => {
  const hasCarton = getHasCarton({syncInventoryForm});
  const syncCategoryForms = syncInventoryForm.syncCategoryForms.filter((syncCategoryForm: any) => {
    return syncCategoryForm.kind === 'CARTON';
  });

  return (
    <FlatList
      data={hasCarton ? syncCategoryForms : []}
      keyExtractor={(syncCategoryForm: any) => syncCategoryForm.categoryId}
      ItemSeparatorComponent={() => <Space height={12} />}
      // @ts-expect-error TS(7031): Binding element 'syncCategoryForm' implicitly has ... Remove this comment to see the full error message
      renderItem={({item: syncCategoryForm}) => (
        <SyncInventoryFormCartonSummaryItem
          syncCategoryForm={syncCategoryForm}
          syncItemForms={getSyncItemForms({syncInventoryForm, syncCategoryForm})}
        />
      )}
      ListEmptyComponent={() => (
        <EmptyContainer>
          <Space height={12} />
          <EmptyTitle>Your carton summary is empty.</EmptyTitle>
          <Space height={8} />
        </EmptyContainer>
      )}
    />
  );
};

export default SyncInventoryFormCartonSummariesList;
