/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: center;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 20px;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LogOutModal.defaultProps;

// @ts-expect-error TS(7022): 'LogOutModal' implicitly has type 'any' because it... Remove this comment to see the full error message
const LogOutModal = ({isOpen, onConfirm, onClose}: Props) => {
  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Container pointerEvents={'box-none'}>
        <Wrapper width={400}>
          <Circle>
            <Emoji component={EmojiText} name={'warning'} />
          </Circle>
          <Content>
            <Title>Are you sure?</Title>
            <Subtitle>
              {`This will log you out of the app and you will need to sign in with your ` +
                `email and password.`}
            </Subtitle>
            <Actions>
              <Button color={colors.gray.secondary} onPress={onClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Button color={colors.red.warning} onPress={onConfirm}>
                <Text>Log Out</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

LogOutModal.defaultProps = {};

export default LogOutModal;
