// Supermove
import {useContext, useEffect} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

// App
import CurrentSyncInventoryFormContext from 'modules/App/contexts/CurrentSyncInventoryFormContext';

const useCurrentSyncInventoryForm = ({inventoryUuid}: any) => {
  const {
    // @ts-expect-error TS(2339): Property 'form' does not exist on type 'undefined'... Remove this comment to see the full error message
    form,
    // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'undefin... Remove this comment to see the full error message
    loading,
    // @ts-expect-error TS(2339): Property 'setCacheInventoryUuid' does not exist on... Remove this comment to see the full error message
    setCacheInventoryUuid,
    // @ts-expect-error TS(2339): Property 'setLoadInventoryUuid' does not exist on ... Remove this comment to see the full error message
    setLoadInventoryUuid,
    // @ts-expect-error TS(2339): Property 'hasUnsavedChanges' does not exist on typ... Remove this comment to see the full error message
    hasUnsavedChanges,
    // @ts-expect-error TS(2339): Property 'setHasUnsavedChanges' does not exist on ... Remove this comment to see the full error message
    setHasUnsavedChanges,
    // @ts-expect-error TS(2339): Property 'hasSavedChanges' does not exist on type ... Remove this comment to see the full error message
    hasSavedChanges,
    // @ts-expect-error TS(2339): Property 'setHasSavedChanges' does not exist on ty... Remove this comment to see the full error message
    setHasSavedChanges,
    // @ts-expect-error TS(2339): Property 'isSubmitting' does not exist on type 'un... Remove this comment to see the full error message
    isSubmitting,
    // @ts-expect-error TS(2339): Property 'setIsSubmitting' does not exist on type ... Remove this comment to see the full error message
    setIsSubmitting,
  } = useContext(CurrentSyncInventoryFormContext);

  invariant(
    !!form,
    'useCurrentSyncInventoryForm must be used with the CurrentSyncInventoryFormContext.Provider',
  );

  useEffect(() => setCacheInventoryUuid(inventoryUuid), [setCacheInventoryUuid, inventoryUuid]);

  return {
    form,
    loading,
    setLoadInventoryUuid,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    hasSavedChanges,
    setHasSavedChanges,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useCurrentSyncInventoryForm;
