// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Float, uuid, withFragment} from '@supermove/utils';

// App
import SyncCollectionForm from './SyncCollectionForm';

const edit = withFragment(
  (room) => {
    const isCollectionEmpty =
      (room as any).collection.takeCount === 0 && (room as any).collection.leaveCount === 0;
    const primaryCategoryId = _.get(room, 'roomType.primaryCategoryId');
    const selectedCategoryId = isCollectionEmpty ? primaryCategoryId : null;

    return {
      inventoryId: (room as any).inventoryId,
      roomTypeId: (room as any).roomTypeId,
      uuid: (room as any).uuid,
      name: (room as any).name,
      description: (room as any).description,
      floorNumber: (room as any).floorNumber,
      isDeleted: (room as any).isDeleted,
      syncCollectionForm: SyncCollectionForm.edit((room as any).collection),
      // Private
      primaryCategoryId,
      selectedCategoryId,
    };
  },
  gql`
    ${SyncCollectionForm.edit.fragment}

    fragment SyncRoomForm_edit on Room {
      inventoryId
      roomTypeId
      uuid
      name
      description
      floorNumber
      isDeleted
      roomType {
        id
        primaryCategoryId
      }
      collection {
        id
        takeCount
        leaveCount
        ...SyncCollectionForm_edit
      }
    }
  `,
);

const _new = ({inventoryId, roomTypeId, name, syncItemForms, primaryCategoryId}: any) => ({
  inventoryId,
  roomTypeId,
  uuid: uuid(),
  name,
  description: '',
  floorNumber: null,
  isDeleted: false,
  syncCollectionForm: SyncCollectionForm.new({syncItemForms}),

  // Private
  primaryCategoryId,
  selectedCategoryId: primaryCategoryId,
});

const copy = ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber,
  isDeleted,
  syncCollectionForm,

  // Private
  primaryCategoryId,

  selectedCategoryId,
}: any) => ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber,
  isDeleted,

  // TODO(mark): Might need to make this a deep copy later on.
  syncCollectionForm,

  // Private
  primaryCategoryId,
  selectedCategoryId,
});

const sync = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ inventoryId, roomTypeId, uuid... Remove this comment to see the full error message
  ({
    inventoryId,
    roomTypeId,
    uuid,
    name,
    description,
    floorNumber,
    isDeleted,
    syncCollectionForm,

    // Private
    primaryCategoryId,
    selectedCategoryId,
  }) => ({
    __typename: 'SyncRoomForm',
    id: uuid,
    inventoryId,
    roomTypeId,
    uuid,
    name,
    description,
    floorNumber,
    isDeleted,
    syncCollectionForm: SyncCollectionForm.sync(syncCollectionForm),

    // Private
    primaryCategoryId,
    selectedCategoryId,
  }),
  gql`
    ${SyncCollectionForm.sync.fragment}

    fragment SyncRoomForm_sync on SyncRoomForm {
      id
      inventoryId
      roomTypeId
      uuid
      name
      description
      floorNumber
      isDeleted
      syncCollectionForm {
        ...SyncCollectionForm_sync
      }

      primaryCategoryId
      selectedCategoryId
    }
  `,
);

const validate = ({prefix, syncRoomForm}: any) => {
  const errors = {};
  if (!syncRoomForm.name) {
    _.set(errors, `${prefix}.name`, 'Please enter a name.');
  }
  return errors;
};

const toForm = ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber,
  isDeleted,
  syncCollectionForm,

  // Private
  primaryCategoryId,

  selectedCategoryId,
}: any) => ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber: Float.toForm(floorNumber),
  isDeleted,
  syncCollectionForm: SyncCollectionForm.toForm(syncCollectionForm),

  // Private
  primaryCategoryId,
  selectedCategoryId,
});

const toMutation = ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber,
  isDeleted,
  syncCollectionForm,
}: any) => ({
  inventoryId,
  roomTypeId,
  uuid,
  name,
  description,
  floorNumber: Float.toMutation(floorNumber),
  isDeleted,
  syncCollectionForm: SyncCollectionForm.toMutation(syncCollectionForm),
});

const SyncRoomForm = {
  // Initialize
  copy,
  edit,
  new: _new,

  // Methods
  sync,
  validate,

  // Conversation
  toForm,
  toMutation,
};

export default SyncRoomForm;
