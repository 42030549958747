/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import EditItemModal from 'modules/Item/components/EditItemModal';
import NewItemModal from 'modules/Item/components/NewItemModal';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 200px;
  padding: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const Info = Styled.Touchable`
  flex: 1;
  justify-content: center;
`;

const Name = Styled.Text`
  ${fontWeight(700)}
  font-size: 10px;
  line-height: 14px;
  color: ${colors.gray.primary};
`;

const Volume = Styled.Text`
  font-size: 8px;
  line-height: 11px;
  color: ${colors.gray.tertiary};
  ${fontWeight(500)}
`;

const Counts = Styled.View`
  flex-direction: row;
`;

const CountContainer = Styled.Touchable<{isHighlighted: boolean; color: string}>`
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 3px;
  background-color: ${({isHighlighted, color}) => (isHighlighted ? colors.alpha(color, 0.1) : colors.white)};
  border-width: 1px;
  border-color: ${({isHighlighted, color}) => (isHighlighted ? color : colors.gray.tertiary)};
`;

const Count = Styled.H8<{vars: {isHighlighted: boolean; color: string}}>`
  ${fontWeight(700)}
  color: ${({vars}) => (vars.isHighlighted ? vars.color : colors.gray.tertiary)};
`;

const Counter = ({count, color, onIncrement}: any) => {
  const isHighlighted = count > 0;

  return (
    <CountContainer
      isHighlighted={isHighlighted}
      color={color}
      onPress={() => {
        setTimeout(() => onIncrement(count + 1), 0);
      }}
    >
      <Count vars={{isHighlighted, color}}>{count}</Count>
    </CountContainer>
  );
};

const SyncItemFormItem = ({syncItemField, syncRoomField, syncItemForm, form}: any) => {
  const editItemModal = useModal({name: 'EditItemModal'});
  const newItemModal = useModal({name: 'NewItemModal'});
  const {setHasUnsavedChanges} = useCurrentSyncInventoryForm({
    inventoryUuid: form.values.syncInventoryForm.uuid,
  });
  const makeSyncItemForm = () => {
    return SyncItemForm.new({
      name: `${syncItemForm.name} custom`,
      takeCount: 1,
      leaveCount: 0,
      weight: SyncItemForm.getWeight(syncItemForm),
      volume: SyncItemForm.getVolume(syncItemForm),
      price: SyncItemForm.getPrice(syncItemForm),
      isDerivedWeight: syncItemForm.isDerivedWeight,
      densityFactor: SyncItemForm.getDensityFactor(syncItemForm),
      kind: syncItemForm.kind,
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Info onPress={editItemModal.handleOpen}>
          <Name numberOfLines={2}>{syncItemForm.name}</Name>
          <Space height={3} />
          <Volume>{`${syncItemForm.volume} CU FT`}</Volume>
        </Info>
        <Space width={5} />
        <Counts>
          <Counter
            count={Float.toFloat(syncItemForm.takeCount)}
            color={colors.green.status}
            onIncrement={(count: any) => {
              setHasUnsavedChanges(true);
              form.setFieldValue(`${syncItemField}.takeCount`, Float.toForm(count));
              setTimeout(() => form.submitForm(), 0);
            }}
          />
          <Space width={10} />
          <Counter
            count={Float.toFloat(syncItemForm.leaveCount)}
            color={colors.gray.tertiary}
            onIncrement={(count: any) => {
              setHasUnsavedChanges(true);
              form.setFieldValue(`${syncItemField}.leaveCount`, Float.toForm(count));
              setTimeout(() => form.submitForm(), 0);
            }}
          />
        </Counts>
      </Container>
      <EditItemModal
        key={editItemModal.key}
        isOpen={editItemModal.isOpen}
        syncRoomField={syncRoomField}
        syncItemField={syncItemField}
        syncItemForm={syncItemForm}
        form={form}
        handleClose={editItemModal.handleClose}
        handleOpenNewCustomItem={newItemModal.handleOpen}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
      <NewItemModal
        key={newItemModal.key}
        isOpen={newItemModal.isOpen}
        name={syncItemForm.name}
        makeSyncItemForm={makeSyncItemForm}
        syncRoomField={syncRoomField}
        form={form}
        handleClose={newItemModal.handleClose}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
    </React.Fragment>
  );
};

export default SyncItemFormItem;
