/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import Modal from 'components/Modal';

const Container = Styled.View`
  padding: 20px;
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const DeleteRoomModal = ({isOpen, syncRoomForm, handleClose, handleCancel, handleSubmit}: any) => {
  return (
    <Modal
      isOpen={isOpen}
      title={'Delete room'}
      subtitle={`Are you sure you would like to delete ${syncRoomForm.name}?`}
      onClose={handleClose}
    >
      <Container>
        <Actions>
          <Button color={colors.gray.tertiary} onPress={handleCancel}>
            <Text color={colors.white}>Cancel</Text>
          </Button>
          <Space width={15} />
          <Button
            color={colors.red.accent}
            onPress={() => handleSubmit({syncRoomForm: {...syncRoomForm, isDeleted: true}})}
          >
            <Text color={colors.red.warning}>Delete</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

export default DeleteRoomModal;
