// Libraries
import React from 'react';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import NewItemModal from 'modules/Item/components/NewItemModal';

const NewCustomItemModal = ({isOpen, syncRoomField, form, handleClose, handleSubmit}: any) => {
  const {setHasUnsavedChanges} = useCurrentSyncInventoryForm({
    inventoryUuid: form.values.syncInventoryForm.uuid,
  });
  const {syncInventoryForm} = form.values;
  const makeSyncItemForm = () => {
    return SyncItemForm.new({
      name: '',
      takeCount: 0,
      leaveCount: 0,
      weight: 0,
      volume: 0,
      price: 0,
      isDerivedWeight: true,
      densityFactor: syncInventoryForm.densityFactor,
      kind: 'ITEM',
    });
  };

  return (
    <NewItemModal
      isOpen={isOpen}
      name={'Add Custom Item'}
      makeSyncItemForm={makeSyncItemForm}
      syncRoomField={syncRoomField}
      form={form}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      setHasUnsavedChanges={setHasUnsavedChanges}
    />
  );
};

export default NewCustomItemModal;
