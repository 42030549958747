/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';

const Container = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  margin-horizontal: 10px;
`;

const Info = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H7`
  flex: 1;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Volume = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const CountContainer = Styled.View`
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: ${(props) =>
    (props as any).isHighlighted
      ? colors.alpha(colors.green.status, 0.1)
      : colors.alpha(colors.gray.tertiary, 0.1)};
`;

const Count = Styled.H7`
  ${fontWeight(500)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isHighlighted ? colors.green.status : colors.gray.tertiary)};
`;

const Counter = ({isColored, count}: any) => {
  const isZero = count === 0;
  const isHighlighted = isColored && !isZero;

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <CountContainer isHighlighted={isHighlighted}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Count vars={{isHighlighted}}>{count}</Count>
    </CountContainer>
  );
};

const ItemCounter = ({kind, syncItemForm}: any) => {
  const takeCount = SyncItemForm.getTakeCount(syncItemForm);
  const leaveCount = SyncItemForm.getLeaveCount(syncItemForm);
  const isTake = kind === 'TAKE';
  const count = isTake ? takeCount : leaveCount;

  return <Counter isColored={isTake} count={count} />;
};

const SyncItemFormSummaryItem = ({kind, syncItemForm}: any) => {
  return (
    <React.Fragment>
      <Container>
        <ItemCounter kind={kind} syncItemForm={syncItemForm} />
        <Space width={10} />
        <Info>
          <Name numberOfLines={1}>{syncItemForm.name}</Name>
          <Space width={10} />
          <Volume>{SyncItemForm.getVolume(syncItemForm)}</Volume>
        </Info>
      </Container>
      <Space height={5} />
    </React.Fragment>
  );
};

export default SyncItemFormSummaryItem;
