/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import SyncItemForm from '@shared/modules/Inventory/forms/SyncItemForm';
import Modal from 'components/Modal';

const Container = Styled.View`
  padding: 20px;
`;

const Field = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const CounterTouchable = Styled.Touchable`
`;

const CounterButton = ({disabled, count, onPress, children}: any) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <Value style={{width: 40}}>{children}</Value>
    </CounterTouchable>
  );
};

const Value = Styled.View`
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props as any).color};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextInput = Styled.TextInput.H7`
`;

const CounterInput = ({name, count, form}: any) => {
  const disabled = count === 0;
  const onDecrement = () => form.setFieldValue(name, Float.toForm(count - 1));
  const onIncrement = () => form.setFieldValue(name, Float.toForm(count + 1));

  return (
    <Row>
      <CounterButton disabled={disabled} onPress={onDecrement}>
        <Icon
          color={disabled ? colors.gray.tertiary : colors.gray.primary}
          size={16}
          source={Icon.Minus}
        />
      </CounterButton>
      <TextInput
        keyboardType={'phone-pad'}
        value={_.get(form, `values.${name}`)}
        placeholder={'Count'}
        onChangeText={(value: any) => form.setFieldValue(name, value)}
        style={{
          width: 80,
          height: 40,
          borderRadius: 0,
          borderColor: colors.gray.border,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          textAlign: 'center',
        }}
      />
      <CounterButton onPress={onIncrement}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Plus} />
      </CounterButton>
    </Row>
  );
};

const NewCustomItemButton = ({handleOpen}: any) => {
  return (
    <Button color={colors.blue.accent} onPress={handleOpen}>
      <Text color={colors.blue.interactive}>Create Custom Version</Text>
    </Button>
  );
};

const EditItemModal = ({
  isOpen,
  syncItemField,
  syncItemForm,
  syncRoomField,
  form,
  handleClose,
  handleOpenNewCustomItem,
  setHasUnsavedChanges,
}: any) => {
  const takeCount = SyncItemForm.getTakeCount(syncItemForm);
  const leaveCount = SyncItemForm.getLeaveCount(syncItemForm);

  return (
    <Modal isOpen={isOpen} title={syncItemForm.name} subtitle={''} onClose={handleClose}>
      <Container>
        <Field>
          <Name>Going</Name>
          <CounterInput name={`${syncItemField}.takeCount`} count={takeCount} form={form} />
        </Field>
        <Field>
          <Name>Not Going</Name>
          <CounterInput name={`${syncItemField}.leaveCount`} count={leaveCount} form={form} />
        </Field>
        <Space height={20} />
        <Actions>
          <Button
            color={colors.blue.interactive}
            onPress={() => {
              form.submitForm();
              setHasUnsavedChanges(true);
              handleClose();
            }}
          >
            <Text color={colors.white}>Done</Text>
          </Button>
          <Space height={10} />
          <NewCustomItemButton
            handleOpen={() => {
              handleClose();
              handleOpenNewCustomItem();
            }}
          />
        </Actions>
      </Container>
    </Modal>
  );
};

export default EditItemModal;
