/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ExternalLink, FlatList, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useIsFocused, useModal, useMountEffect, useNavigation, useQuery} from '@supermove/hooks';
import {DeviceInfo, Permissions} from '@supermove/sdk';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import LogOutSidebarItem from 'modules/App/components/LogOutSidebarItem';
import Sidebar from 'modules/App/components/Sidebar';
import JobListItem from 'modules/Job/List/components/JobListItem';

const Row = Styled.View`
  flex-direction: row;
`;

const Items = Styled.View`
  flex: 1;
`;

const ListJobsSidebar = ({datetime}: any) => {
  const {navigator} = useNavigation();

  return (
    <Sidebar>
      <Items>
        <Space height={15} />
        <Sidebar.Item
          isFirst
          isSelected
          icon={Icon.Calendar}
          text={'Calendar'}
          onPress={() => navigator.navigate('ListJobs')}
        />
      </Items>
      <Sidebar.Item
        icon={Icon.Cog}
        text={'Settings'}
        onPress={() => navigator.navigate('Settings')}
      />
      <LogOutSidebarItem />
    </Sidebar>
  );
};

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
`;

const DateTitle = Styled.H3`
  ${fontWeight(700)}
  color: ${colors.blue.accentDark};
`;

const ListJobsHeader = ({datetime}: any) => {
  return (
    <Header>
      <DateTitle>{Datetime.toDisplayDate(datetime, Datetime.DISPLAY_DATE)}</DateTitle>
    </Header>
  );
};

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View`
  flex: 1;
`;

const JobsEmpty = Styled.View`
  align-items: center;
  padding-horizontal: 30px;
  padding-vertical: 80px;
`;

const JobsEmptyMessage = Styled.H5`
  color: ${colors.gray.primary};
  text-align: center;
`;

const getJobs = ({jobs}: any) => {
  return _.orderBy(jobs, ['startTime1']);
};

const AcknowledgeLocationTrackingModal = ({isOpen, handleClose}: any) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>
        {`Supermove collects location data to enable the ` +
          `consumer to track the location of the tablet even ` +
          `when the app is closed or not in use.`}
      </SmallModal.BoldText>
      <Space height={16} />
      <Row>
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <SmallModal.ButtonText color={colors.blue.interactive}>
            Privacy Policy
          </SmallModal.ButtonText>
        </ExternalLink>
      </Row>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Deny</SmallModal.Button>
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={() => {
            Permissions.handleAndroidLocationTrackingPermission();
            handleClose();
          }}
        >
          Accept
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const ListJobsPage = () => {
  // Get permission for location tracking
  const acknowledgeLocationTrackingModal = useModal({name: 'Acknowledge Location Tracking Modal'});
  useMountEffect(() => {
    const isAndroid = DeviceInfo.getPlatform() === 'ANDROID';
    if (isAndroid) {
      Permissions.handleAndroidCheckLocationTrackingPermission({
        handleGetPermission: acknowledgeLocationTrackingModal.handleOpen,
      });
    }
  });

  const datetime = Datetime.today;
  const {loading, data, refetch} = useQuery(ListJobsPage.query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      date: Datetime.toDate(datetime),
    },
  });

  // Reload the list of jobs whenever this page becomes focused.
  const isFocused = useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Container>
      <ListJobsSidebar datetime={datetime} />
      <Loading loading={loading}>
        {() => (
          <Content>
            <ListJobsHeader datetime={datetime} />
            <FlatList
              refreshing={loading || !isFocused}
              data={getJobs({jobs: data.viewer.jobsForEstimatorApp})}
              keyExtractor={(job: any) => job.id}
              onRefresh={refetch}
              renderItem={({item: job, index}: any) => <JobListItem job={job} />}
              ListEmptyComponent={() =>
                loading ? null : (
                  <JobsEmpty>
                    <JobsEmptyMessage>There are no estimates for today.</JobsEmptyMessage>
                  </JobsEmpty>
                )
              }
            />
          </Content>
        )}
      </Loading>
      <AcknowledgeLocationTrackingModal
        isOpen={acknowledgeLocationTrackingModal.isOpen}
        handleClose={acknowledgeLocationTrackingModal.handleClose}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListJobsPage.query = gql`
  ${JobListItem.fragment}

  query ListJobsPage($date: String!) {
    ${gql.query}
    viewer {
      id
      jobsForEstimatorApp(date: $date) {
        id
        startTime1
        ...JobListItem
      }
    }
  }
`;

export default ListJobsPage;
