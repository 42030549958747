/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SyncInventoryForm from '@shared/modules/Inventory/forms/SyncInventoryForm';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import SyncInventoryCounts from 'modules/Inventory/components/SyncInventoryCounts';
import SyncInventoryFormCartonSummariesList from 'modules/Job/Show/Inventory/components/SyncInventoryFormCartonSummariesList';
import SyncRoomFormSummariesList from 'modules/Job/Show/Inventory/components/SyncRoomFormSummariesList';
import JobSidebar from 'modules/Job/components/JobSidebar';

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const HeaderTitle = Styled.H5`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const InventoryShowJobHeader = ({syncInventoryForm}: any) => {
  return (
    <Header>
      <HeaderTitle numberOfLines={1}>
        {`Survey Items (${SyncInventoryForm.getTotalItemsCount(syncInventoryForm)})`}
      </HeaderTitle>
      <SyncInventoryCounts syncInventoryForm={syncInventoryForm} />
    </Header>
  );
};

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View`
  flex: 1;
  background-color: ${colors.gray.border};
`;

const InventoryContent = Styled.View`
  padding-horizontal: 20px;
`;

const Section = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const InventorySection = ({icon, title, action, children}: any) => {
  return (
    <Section>
      <Row>
        <Icon color={colors.gray.primary} size={12} source={icon} />
        <Space width={5} />
        <Title>{title}</Title>
        <Space width={5} />
        {action}
      </Row>
      <Space height={10} />
      {children}
    </Section>
  );
};

const InventoryShowJobPageContent = ({job}: any) => {
  const inventoryUuid = job.inventory.uuid;
  const {form} = useCurrentSyncInventoryForm({inventoryUuid});
  const {syncInventoryForm} = form.values;

  return (
    <Content>
      <InventoryShowJobHeader syncInventoryForm={syncInventoryForm} />
      <ScrollView style={{flex: 1}}>
        <InventoryContent>
          <Space height={20} />
          <InventorySection icon={Icon.BoxOpen} title={'Carton summary'}>
            <SyncInventoryFormCartonSummariesList syncInventoryForm={syncInventoryForm} />
          </InventorySection>
          <Space height={20} />
          <InventorySection icon={Icon.Archive} title={'Room summary (Cartons included)'}>
            <SyncRoomFormSummariesList syncRoomForms={syncInventoryForm.syncRoomForms} />
          </InventorySection>
          <Space height={32} />
        </InventoryContent>
      </ScrollView>
    </Content>
  );
};

const InventoryShowJobPage = () => {
  const {params} = useNavigation();
  const {jobUuid} = params;
  const {data} = useQuery(InventoryShowJobPage.query, {
    fetchPolicy: 'cache-only',
    variables: {
      jobUuid,
    },
  });

  return (
    <Container>
      <JobSidebar selected={'InventoryShowJob'} jobUuid={jobUuid} />
      {!!data && <InventoryShowJobPageContent job={data.job} />}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryShowJobPage.query = gql`
  query InventoryShowJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      inventory {
        id
        uuid
      }
    }
  }
`;

export default InventoryShowJobPage;
