// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, uuid, withFragment} from '@supermove/utils';

const getDensityFactor = ({densityFactor}: any) => {
  return Float.toFloat(densityFactor);
};

const getLeaveCount = ({leaveCount}: any) => {
  return Float.toFloat(leaveCount);
};

const getPrice = ({price}: any) => {
  return Currency.convertToCents(price);
};

const getTakeCount = ({takeCount}: any) => {
  return Float.toFloat(takeCount);
};

const getVolume = ({volume}: any) => {
  return Float.toFloat(volume);
};

const getWeight = ({weight}: any) => {
  return Float.toFloat(weight);
};

const edit = withFragment(
  (item) => ({
    itemTypeId: (item as any).itemTypeId,
    uuid: (item as any).uuid,
    name: (item as any).name,
    takeCount: (item as any).takeCount,
    leaveCount: (item as any).leaveCount,
    weight: (item as any).weight,
    volume: (item as any).volume,
    price: (item as any).price,
    isDerivedWeight: (item as any).isDerivedWeight,
    isDeleted: (item as any).isDeleted,
    // Private
    densityFactor: (item as any).weight / (item as any).volume,
    categoryIds: _.get(item, 'itemType.itemTypeCategories', []).map((itemTypeCategory: any) => {
      return String(itemTypeCategory.categoryId);
    }),
    kind: _.get(item, 'itemType.kind'),
  }),
  gql`
    fragment SyncItemForm_edit on Item {
      id
      itemTypeId
      uuid
      name
      takeCount
      leaveCount
      weight
      volume
      price
      isDerivedWeight
      isDeleted
      itemType {
        id
        kind
        itemTypeCategories {
          id
          categoryId
        }
      }
    }
  `,
);

const _new = ({
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  densityFactor,
  kind,
}: any) => ({
  itemTypeId: null,
  uuid: uuid(),
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  isDeleted: false,

  // Private
  densityFactor,
  categoryIds: [],
  kind,
});

const newForSyncItemTypeForm = (syncItemTypeForm: any) => ({
  itemTypeId: syncItemTypeForm.itemTypeId,
  uuid: uuid(),
  name: syncItemTypeForm.name,
  takeCount: 0,
  leaveCount: 0,
  weight: syncItemTypeForm.weight,
  volume: syncItemTypeForm.volume,
  price: syncItemTypeForm.price,
  isDerivedWeight: syncItemTypeForm.isDerivedWeight,
  isDeleted: false,

  // Private
  densityFactor: syncItemTypeForm.densityFactor,

  categoryIds: syncItemTypeForm.categoryIds,
  kind: syncItemTypeForm.kind,
});

const sync = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ itemTypeId, uuid, name, takeC... Remove this comment to see the full error message
  ({
    itemTypeId,
    uuid,
    name,
    takeCount,
    leaveCount,
    weight,
    volume,
    price,
    isDerivedWeight,
    isDeleted,

    // Private
    densityFactor,
    categoryIds,
    kind,
  }) => ({
    __typename: 'SyncItemForm',
    id: uuid,
    itemTypeId,
    uuid,
    name,
    takeCount,
    leaveCount,
    weight,
    volume,
    price,
    isDerivedWeight,
    isDeleted,

    // Private
    densityFactor,
    categoryIds,
    kind,
  }),
  gql`
    fragment SyncItemForm_sync on SyncItemForm {
      id
      itemTypeId
      uuid
      name
      takeCount
      leaveCount
      weight
      volume
      price
      isDerivedWeight
      isDeleted

      densityFactor
      categoryIds
      kind
    }
  `,
);

const validate = ({prefix, syncItemForm}: any) => {
  const errors = {};
  if (!syncItemForm.name) {
    _.set(errors, `${prefix}.name`, 'Please enter a name.');
  }
  return errors;
};

const toForm = ({
  itemTypeId,
  uuid,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  isDeleted,

  // Private
  densityFactor,

  categoryIds,
  kind,
}: any) => ({
  itemTypeId,
  uuid,
  name,
  takeCount: Float.toForm(takeCount),
  leaveCount: Float.toForm(leaveCount),
  weight: Float.toForm(weight),
  volume: Float.toForm(volume),
  price: Currency.toForm(price),
  isDerivedWeight,
  isDeleted,

  // Private
  densityFactor: Float.toForm(densityFactor),
  categoryIds,
  kind,
});

const toMutation = ({
  itemTypeId,
  uuid,
  name,
  takeCount,
  leaveCount,
  weight,
  volume,
  price,
  isDerivedWeight,
  isDeleted,
}: any) => ({
  itemTypeId,
  uuid,
  name,
  takeCount: Float.toMutation(takeCount),
  leaveCount: Float.toMutation(leaveCount),
  weight: Float.toMutation(weight),
  volume: Float.toMutation(volume),
  price: Currency.toMutation(price),
  isDerivedWeight,
  isDeleted,
});

const SyncItemForm = {
  // Initialize
  edit,
  new: _new,
  newForSyncItemTypeForm,

  // Getters
  getDensityFactor,
  getLeaveCount,
  getPrice,
  getTakeCount,
  getVolume,
  getWeight,

  // Methods
  sync,
  validate,

  // Conversion
  toForm,
  toMutation,
};

export default SyncItemForm;
