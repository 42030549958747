/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useDebouncedCallback, useEffect, useInternet, useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import useSyncInventoryMutation from '@shared/modules/Inventory/hooks/useSyncInventoryMutation';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import SyncInventoryFormOfflineModal from 'modules/Job/Show/components/SyncInventoryFormOfflineModal';

const Row = Styled.View`
  flex-direction: row;
`;

const SyncInventoryTouchable = Styled.Touchable`
  margin-horizontal: 15px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props as any).color};
  border-radius: 80px;
`;

const Indicator = Styled.Loading`
`;

const SyncInventory = Styled.View`
`;

const SyncInventoryText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const SyncMessage = Styled.H8`
  font-size: 10px;
  ${fontWeight(500)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isConnected ? colors.green.status : colors.red.warning)};
  text-align: center;
`;

const getSaveButtonColor = ({isConnected, isSaveDisabled}: any) => {
  if (!isConnected) {
    return colors.red.accent;
  }
  if (isSaveDisabled) {
    return colors.gray.disabled;
  }
  return colors.green.accent;
};

const SyncInventoryTouchableContent = ({
  isSubmitting,
  isConnected,
  hasUnsavedChanges,
  hasSavedChanges,
}: any) => {
  if (isSubmitting) {
    return <Indicator color={colors.white} />;
  }
  if (!isConnected) {
    return (
      <Row>
        <SyncInventoryText color={colors.red.warning}>Save</SyncInventoryText>
        <Space width={6} />
        <Icon source={Icon.ExclamationTriangle} size={12} color={colors.red.warning} />
      </Row>
    );
  }
  if (hasUnsavedChanges) {
    return <SyncInventoryText color={colors.green.status}>Save</SyncInventoryText>;
  }
  if (hasSavedChanges) {
    return (
      <Row>
        <SyncInventoryText color={colors.white}>Saved</SyncInventoryText>
        <Space width={6} />
        <Icon source={Icon.Check} size={12} color={colors.white} />
      </Row>
    );
  }
  return <SyncInventoryText color={colors.white}>Save</SyncInventoryText>;
};

const SyncInventorySidebarSectionContent = ({form, isConnected}: any) => {
  const {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    hasSavedChanges,
    setHasSavedChanges,
    isSubmitting,
    setIsSubmitting,
  } = useCurrentSyncInventoryForm({inventoryUuid: form.values.syncInventoryForm.uuid});
  const syncInventoryFormOfflineModal = useModal({name: 'SyncInventoryFormOfflineModal'});
  const {syncInventoryForm} = form.values;
  const {handleSubmit} = useSyncInventoryMutation({
    form,
    onSuccess: () => {
      form.setFieldValue('syncInventoryForm.lastSyncedAt', Datetime.toTimestamp(Datetime.now));
      setHasUnsavedChanges(false);
      setHasSavedChanges(true);
      setIsSubmitting(false);
      console.log('Sync Success');
    },
    onError: () => {
      setIsSubmitting(false);
      console.log('Sync Error');
    },
  });

  const {submitCount} = form;
  const handleSubmitIfConnected = (isConnected: any) => {
    if (isConnected && hasUnsavedChanges) {
      handleSubmit();
    }
  };

  // We autosave every 15 seconds only when the number of take or leave count changes.
  const handleDebouncedSave = useDebouncedCallback(handleSubmitIfConnected, 60 * 1000);
  useEffect(
    () => handleDebouncedSave(isConnected),
    [handleDebouncedSave, isConnected, submitCount],
  );
  const isSaveDisabled = !hasUnsavedChanges || isSubmitting;

  return (
    <SyncInventory>
      <SyncInventoryTouchable
        disabled={isSaveDisabled}
        // @ts-expect-error TS(2769): No overload matches this call.
        color={getSaveButtonColor({isSaveDisabled, isConnected})}
        onPress={() => {
          if (isConnected) {
            setIsSubmitting(true);
            handleSubmit();
          } else {
            syncInventoryFormOfflineModal.handleOpen();
          }
        }}
      >
        <SyncInventoryTouchableContent
          isSubmitting={isSubmitting}
          isConnected={isConnected}
          hasUnsavedChanges={hasUnsavedChanges}
          hasSavedChanges={hasSavedChanges}
        />
      </SyncInventoryTouchable>
      <Space height={4} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SyncMessage vars={{isConnected}}>
        {isConnected ? 'You are online' : 'You are offline'}
      </SyncMessage>
      <Space height={4} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SyncMessage vars={{isConnected}}>
        {`Last saved ${Datetime.timeAgo(syncInventoryForm.lastSyncedAt)}`}
      </SyncMessage>
      <SyncInventoryFormOfflineModal
        key={syncInventoryFormOfflineModal.key}
        isOpen={syncInventoryFormOfflineModal.isOpen}
        handleClose={syncInventoryFormOfflineModal.handleClose}
      />
    </SyncInventory>
  );
};

const SyncInventorySidebarSection = ({form}: any) => {
  const {hasFetched, isConnected} = useInternet();

  if (!hasFetched) {
    return null;
  }

  return <SyncInventorySidebarSectionContent isConnected={isConnected} form={form} />;
};

export default SyncInventorySidebarSection;
