/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import LogOutSidebarItem from 'modules/App/components/LogOutSidebarItem';
import Sidebar from 'modules/App/components/Sidebar';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import SyncInventorySidebarSection from 'modules/Job/Show/components/SyncInventorySidebarSection';
import NewRoomModal from 'modules/Room/components/NewRoomModal';

const JobItems = Styled.View`
`;

const JobName = Styled.H8`
  ${fontWeight(700)}
  padding-horizontal: 15px;
  color: ${colors.gray.primary};
`;

const Subitem = Styled.View`
  background-color: ${colors.white};
`;

const LinkTouchable = Styled.Touchable`
  padding-vertical: 10px;
  padding-horizontal: 20px;
`;

const LinkText = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const ViewInventoryItem = ({selected, jobUuid, roomUuid, form, setHasUnsavedChanges}: any) => {
  const {navigator} = useNavigation();
  const newRoomModal = useModal({name: 'NewRoomModal'});
  const {syncInventoryForm} = form.values;

  // Remove the deleted rooms from the sidebar.
  const syncRoomForms = syncInventoryForm.syncRoomForms.filter((syncRoomForm: any) => {
    return !syncRoomForm.isDeleted;
  });

  // Adds the new SyncRoomForm to the SyncInventoryForm, closes the current modal and navigates
  // to the new screen (all via the client side uuid).
  const handleAddNewRoom = async ({syncRoomForm}: any) => {
    const index = syncInventoryForm.syncRoomForms.length;
    await form.setFieldValue(`syncInventoryForm.syncRoomForms.${index}`, syncRoomForm);
    await form.submitForm();
    setHasUnsavedChanges(true);

    // After the form submits, we navigate to the new room. Modal closing has been handled by
    // the internals of the modals (NewRoomModal and NewCustomRoomModal).
    navigator.navigate('ShowRoom', {jobUuid, roomUuid: syncRoomForm.uuid});
  };

  return (
    <React.Fragment>
      <Sidebar.Item
        isSelected={['InventoryShowJob', 'ShowRoom'].includes(selected)}
        icon={Icon.Archive}
        text={'Survey Items'}
        onPress={() => navigator.navigate('InventoryShowJob', {jobUuid})}
      >
        <Subitem>
          <Space height={5} />
          <LinkTouchable onPress={newRoomModal.handleOpen}>
            <LinkText>+ Add New Room</LinkText>
          </LinkTouchable>
        </Subitem>
        {syncRoomForms.map((syncRoomForm: any) => (
          <Subitem key={syncRoomForm.uuid}>
            <Space height={5} />
            <Sidebar.Subitem
              isSelected={selected === 'ShowRoom' && syncRoomForm.uuid === roomUuid}
              text={syncRoomForm.name}
              onPress={() => {
                navigator.navigate('ShowRoom', {jobUuid, roomUuid: syncRoomForm.uuid});
              }}
            />
            <Space height={5} />
          </Subitem>
        ))}
      </Sidebar.Item>
      <NewRoomModal
        isOpen={newRoomModal.isOpen}
        syncInventoryForm={syncInventoryForm}
        handleClose={newRoomModal.handleClose}
        handleSubmit={handleAddNewRoom}
      />
    </React.Fragment>
  );
};

const JobSidebarContent = ({selected, jobUuid, roomUuid, job}: any) => {
  const {navigator} = useNavigation();
  const inventoryUuid = job.inventory.uuid;
  const {form, setHasUnsavedChanges} = useCurrentSyncInventoryForm({inventoryUuid});
  const {syncInventoryForm} = form.values;

  return (
    <Sidebar>
      <ScrollView>
        <Space height={15} />
        <Sidebar.Item
          isFirst
          icon={Icon.Calendar}
          text={'Calendar'}
          onPress={() => navigator.navigate('ListJobs')}
        />
        <JobItems>
          <Space height={20} />
          <JobName numberOfLines={1}>{Job.getFullName(job)}</JobName>
          <Space height={10} />
          <Sidebar.Item
            isFirst
            isSelected={selected === 'ShowJob'}
            icon={Icon.Star}
            text={'View Info'}
            onPress={() => navigator.navigate('ShowJob', {jobUuid})}
          />
          <ViewInventoryItem
            selected={selected}
            jobUuid={jobUuid}
            roomUuid={roomUuid}
            form={form}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </JobItems>
        <Space height={10} />
        {!!syncInventoryForm.inventoryId && <SyncInventorySidebarSection form={form} />}
      </ScrollView>
      <LogOutSidebarItem />
    </Sidebar>
  );
};

const JobSidebar = ({selected, jobUuid, roomUuid}: any) => {
  const {data} = useQuery(JobSidebar.query, {
    fetchPolicy: 'cache-only',
    variables: {
      jobUuid,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <JobSidebarContent selected={selected} jobUuid={jobUuid} roomUuid={roomUuid} job={data.job} />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobSidebar.query = gql`
  ${Job.getFullName.fragment}

  query JobSidebar($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      inventory {
        id
        uuid
      }
      ...Job_getFullName
    }
  }
`;

export default JobSidebar;
