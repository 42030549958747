// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {uuid, withFragment} from '@supermove/utils';

import SyncItemForm from './SyncItemForm';

const getLeaveCount = ({syncItemForms}: any) => {
  return _.sumBy(syncItemForms, (itemForm) => SyncItemForm.getLeaveCount(itemForm));
};

const getTakeCount = ({syncItemForms}: any) => {
  return _.sumBy(syncItemForms, (itemForm) => SyncItemForm.getTakeCount(itemForm));
};

const getTotalVolume = ({syncItemForms}: any) => {
  return _.round(
    _.sumBy(syncItemForms, (itemForm) => {
      return SyncItemForm.getTakeCount(itemForm) * SyncItemForm.getVolume(itemForm);
    }),
    2,
  );
};

const getTotalWeight = ({syncItemForms}: any) => {
  return _.round(
    _.sumBy(syncItemForms, (itemForm) => {
      return SyncItemForm.getTakeCount(itemForm) * SyncItemForm.getWeight(itemForm);
    }),
    2,
  );
};

const edit = withFragment(
  (collection) => ({
    uuid: (collection as any).uuid,
    syncItemForms: _.orderBy(
      (collection as any).items.map((item: any) => SyncItemForm.edit(item)),
      ['kind'],
      ['desc'],
    ),
    // Private
    searchQuery: '',
  }),
  gql`
    ${SyncItemForm.edit.fragment}

    fragment SyncCollectionForm_edit on Collection {
      uuid
      items {
        id
        ...SyncItemForm_edit
      }
    }
  `,
);

const _new = ({syncItemForms}: any) => ({
  uuid: uuid(),
  syncItemForms,

  // Private
  searchQuery: '',
});

const sync = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ uuid, syncItemForms }: { uuid... Remove this comment to see the full error message
  ({uuid, syncItemForms}) => ({
    __typename: 'SyncCollectionForm',
    id: uuid,
    uuid,
    syncItemForms: syncItemForms.map((syncItemForm: any) => SyncItemForm.sync(syncItemForm)),
  }),
  gql`
    ${SyncItemForm.sync.fragment}

    fragment SyncCollectionForm_sync on SyncCollectionForm {
      id
      uuid
      syncItemForms {
        ...SyncItemForm_sync
      }
    }
  `,
);

const toForm = ({uuid, syncItemForms, searchQuery}: any) => ({
  uuid,
  syncItemForms: syncItemForms.map((itemForm: any) => SyncItemForm.toForm(itemForm)),

  // Private
  searchQuery,
});

const toMutation = ({uuid, syncItemForms, searchQuery}: any) => ({
  uuid,
  syncItemForms: syncItemForms.map((itemForm: any) => SyncItemForm.toMutation(itemForm)),
});

const SyncCollectionForm = {
  // Initialize
  edit,
  new: _new,

  // Getters
  getLeaveCount,
  getTakeCount,
  getTotalVolume,
  getTotalWeight,

  // Methods
  sync,

  // Conversion
  toForm,
  toMutation,
};

export default SyncCollectionForm;
