/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
// @ts-expect-error TS(2307): Cannot find module 'modules/Job/Show/Inventory/ass... Remove this comment to see the full error message
import EmptyListImage from 'modules/Job/Show/Inventory/assets/empty-list-image.png';
import SyncRoomFormSummaryItem from 'modules/Job/Show/Inventory/components/SyncRoomFormSummaryItem';

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EmptySubtitle = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const EmptyImage = Styled.Image`
  width: 200px;
  height: 112px;
`;

const SyncRoomFormSummariesList = ({syncRoomForms}: any) => {
  return (
    <FlatList
      data={syncRoomForms}
      keyExtractor={(syncRoomForm: any) => syncRoomForm.uuid}
      ItemSeparatorComponent={({leadingItem}: any) =>
        !leadingItem.isDeleted && <Space height={12} />
      }
      // @ts-expect-error TS(7031): Binding element 'syncRoomForm' implicitly has an '... Remove this comment to see the full error message
      renderItem={({item: syncRoomForm}) => <SyncRoomFormSummaryItem syncRoomForm={syncRoomForm} />}
      ListEmptyComponent={() => (
        <EmptyContainer>
          <Space height={60} />
          <EmptyImage source={{uri: EmptyListImage}} />
          <Space height={26} />
          <EmptyTitle>Your room summary is empty.</EmptyTitle>
          <Space height={2} />
          <EmptySubtitle>Add a new room to start surveying items.</EmptySubtitle>
        </EmptyContainer>
      )}
    />
  );
};

export default SyncRoomFormSummariesList;
