// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import SyncCollectionForm from '@shared/modules/Inventory/forms/SyncCollectionForm';
import SyncRoomForm from '@shared/modules/Inventory/forms/SyncRoomForm';

// Used for getting item counts on the estimator app. Estimator app will cache form data
// instead of immediately writing to the db and updating itself. We need to therefore
// manually handle rooms that get deleted on the ui because initially the room will get
// marked as isDeleted = true, but the form and data for the room will continue to persist
// on the frontend.
const _getSyncCollectionForms = ({syncRoomForms}: any) => {
  return _.compact(
    syncRoomForms.map(
      (syncRoomForm: any) => !syncRoomForm.isDeleted && syncRoomForm.syncCollectionForm,
    ),
  );
};

// Used for getting item counts on the estimator app. See comment for _getSyncCollectionForms.
const getAllSyncItemForms = ({syncRoomForms}: any) => {
  const allSyncItemForms = syncRoomForms.map(
    (syncRoomForm: any) => !syncRoomForm.isDeleted && syncRoomForm.syncCollectionForm.syncItemForms,
  );
  return _.flatten(_.compact(allSyncItemForms));
};

const getLeaveCount = ({syncRoomForms}: any) => {
  return _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getLeaveCount);
};

const getTakeCount = ({syncRoomForms}: any) => {
  return _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTakeCount);
};

const getTotalItemsCount = ({syncRoomForms}: any) => {
  return getLeaveCount({syncRoomForms}) + getTakeCount({syncRoomForms});
};

const getTotalVolume = ({syncRoomForms}: any) => {
  return _.round(
    _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTotalVolume),
    2,
  );
};

const getTotalWeight = ({syncRoomForms}: any) => {
  return _.round(
    _.sumBy(_getSyncCollectionForms({syncRoomForms}), SyncCollectionForm.getTotalWeight),
    2,
  );
};

const edit = withFragment(
  (inventory) => ({
    inventoryId: (inventory as any).id,
    uuid: (inventory as any).uuid,
    densityFactor: (inventory as any).densityFactor,
    syncRoomForms: (inventory as any).rooms.map((room: any) => SyncRoomForm.edit(room)),
    // Private
    isEnabledInventoryPriceField: (inventory as any).project.organization.features
      .isEnabledInventoryPriceField,
    lastSyncedAt: (inventory as any).lastSyncedAt,
    syncCategoryForms: (inventory as any).project.organization.categories.map((category: any) => ({
      categoryId: category.id,
      name: category.name,
      isShared: category.isShared,
      kind: category.kind,
    })),
    syncItemTypeForms: (inventory as any).project.organization.itemTypes.map((itemType: any) => ({
      itemTypeId: itemType.id,
      name: itemType.name,
      weight: itemType.weight,
      volume: itemType.volume,
      price: itemType.price,
      isDerivedWeight: itemType.isDerivedWeight,
      densityFactor: (inventory as any).densityFactor,
      categoryIds: itemType.itemTypeCategories.map((itemTypeCategory: any) => {
        return String(itemTypeCategory.categoryId);
      }),
      kind: itemType.kind,
    })),
    syncRoomTypeForms: (inventory as any).project.organization.roomTypes.map((roomType: any) => ({
      roomTypeId: roomType.id,
      primaryCategoryId: roomType.primaryCategoryId,
      name: roomType.name,
    })),
  }),
  gql`
    ${SyncRoomForm.edit.fragment}

    fragment SyncInventoryForm_edit on Inventory {
      id
      uuid
      densityFactor
      lastSyncedAt
      project {
        id
        organization {
          id
          features {
            isEnabledInventoryPriceField: isEnabled(feature: "INVENTORY_PRICE_FIELD")
          }
          categories {
            id
            name
            isShared
            kind
          }
          itemTypes {
            id
            name
            weight
            volume
            price
            isDerivedWeight
            kind
            itemTypeCategories {
              id
              categoryId
            }
          }
          roomTypes {
            id
            primaryCategoryId
            name
          }
        }
      }
      rooms {
        id
        ...SyncRoomForm_edit
      }
    }
  `,
);

const _getDefaultSyncRoomForms = (syncInventoryForm: any) => {
  if (syncInventoryForm.syncRoomForms.length > 0) {
    return syncInventoryForm.syncRoomForms;
  }
  return [];
};

const load = withFragment(
  (inventory) => {
    const syncInventoryForm = edit(inventory);
    const syncRoomForms = _getDefaultSyncRoomForms(syncInventoryForm);

    return {
      ...syncInventoryForm,
      syncRoomForms,
    };
  },
  gql`
    ${edit.fragment}

    fragment SyncInventoryForm_load on Inventory {
      id
      ...SyncInventoryForm_edit
    }
  `,
);

const sync = withFragment(
  (syncInventoryForm) => ({
    __typename: 'SyncInventoryForm',
    id: (syncInventoryForm as any).uuid,
    inventoryId: (syncInventoryForm as any).inventoryId,
    uuid: (syncInventoryForm as any).uuid,
    densityFactor: (syncInventoryForm as any).densityFactor,
    syncRoomForms: (syncInventoryForm as any).syncRoomForms.map((syncRoomForm: any) => {
      return SyncRoomForm.sync(syncRoomForm);
    }),
    // Private
    isEnabledInventoryPriceField: (syncInventoryForm as any).isEnabledInventoryPriceField,
    lastSyncedAt: (syncInventoryForm as any).lastSyncedAt,
    syncCategoryForms: (syncInventoryForm as any).syncCategoryForms.map(
      (syncCategoryForm: any) => ({
        __typename: 'SyncCategoryForm',
        id: syncCategoryForm.categoryId,
        categoryId: syncCategoryForm.categoryId,
        name: syncCategoryForm.name,
        isShared: syncCategoryForm.isShared,
        kind: syncCategoryForm.kind,
      }),
    ),
    syncItemTypeForms: (syncInventoryForm as any).syncItemTypeForms.map(
      (syncItemTypeForm: any) => ({
        __typename: 'SyncItemTypeForm',
        id: syncItemTypeForm.itemTypeId,
        itemTypeId: syncItemTypeForm.itemTypeId,
        name: syncItemTypeForm.name,
        weight: syncItemTypeForm.weight,
        volume: syncItemTypeForm.volume,
        price: syncItemTypeForm.price,
        isDerivedWeight: syncItemTypeForm.isDerivedWeight,
        densityFactor: syncItemTypeForm.densityFactor,
        categoryIds: syncItemTypeForm.categoryIds,
        kind: syncItemTypeForm.kind,
      }),
    ),
    syncRoomTypeForms: (syncInventoryForm as any).syncRoomTypeForms.map(
      (syncRoomTypeForm: any) => ({
        __typename: 'SyncRoomTypeForm',
        id: syncRoomTypeForm.roomTypeId,
        roomTypeId: syncRoomTypeForm.roomTypeId,
        primaryCategoryId: syncRoomTypeForm.primaryCategoryId,
        name: syncRoomTypeForm.name,
      }),
    ),
  }),
  gql`
    ${SyncRoomForm.sync.fragment}

    fragment SyncInventoryForm_sync on SyncInventoryForm {
      id
      inventoryId
      uuid
      densityFactor
      lastSyncedAt
      isEnabledInventoryPriceField
      syncRoomForms {
        ...SyncRoomForm_sync
      }
      syncCategoryForms {
        id
        categoryId
        name
        isShared
        kind
      }
      syncItemTypeForms {
        id
        itemTypeId
        name
        weight
        volume
        price
        isDerivedWeight
        densityFactor
        categoryIds
        kind
      }
      syncRoomTypeForms {
        id
        roomTypeId
        primaryCategoryId
        name
      }
    }
  `,
);

const _new = () => ({
  inventoryId: null,
  uuid: null,
  densityFactor: 0,
  syncRoomForms: [],

  // Private
  isEnabledInventoryPriceField: false,
  lastSyncedAt: null,
  syncCategoryForms: [],
  syncItemTypeForms: [],
  syncRoomTypeForms: [],
});

const toForm = ({
  inventoryId,
  uuid,
  densityFactor,
  syncRoomForms,

  // Private
  isEnabledInventoryPriceField,

  lastSyncedAt,
  syncCategoryForms,
  syncItemTypeForms,
  syncRoomTypeForms,
}: any) => ({
  inventoryId,
  uuid,
  densityFactor,
  syncRoomForms: syncRoomForms.map((syncRoomForm: any) => SyncRoomForm.toForm(syncRoomForm)),

  // Private
  isEnabledInventoryPriceField,
  lastSyncedAt,
  syncCategoryForms,
  syncItemTypeForms,
  syncRoomTypeForms,
});

const toMutation = ({inventoryId, densityFactor, syncRoomForms}: any) => ({
  inventoryId,
  densityFactor,
  syncRoomForms: syncRoomForms.map((syncRoomForm: any) => SyncRoomForm.toMutation(syncRoomForm)),
});

const SyncInventoryForm = {
  // Initialize
  new: _new,
  edit,
  load,

  // Methods
  sync,

  // Getters
  getAllSyncItemForms,
  getLeaveCount,
  getTakeCount,
  getTotalItemsCount,
  getTotalVolume,
  getTotalWeight,

  // Serialize
  toForm,
  toMutation,
};

export default SyncInventoryForm;
