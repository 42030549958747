// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useCallback, useEffect, useForm, useQuery, useState} from '@supermove/hooks';

// App
import SyncInventoryForm from '@shared/modules/Inventory/forms/SyncInventoryForm';
// @ts-expect-error
import {client} from 'config';
import CurrentSyncInventoryFormContext from 'modules/App/contexts/CurrentSyncInventoryFormContext';

const useCurrentSyncInventoryCache = ({setSyncInventoryForm}: any) => {
  const [inventoryUuid, setInventoryUuid] = useState(null);
  const {data} = useQuery(useCurrentSyncInventoryCache.query, {
    fetchPolicy: 'cache-only',
    skip: !inventoryUuid,
    variables: {inventoryUuid},
  });

  useEffect(() => {
    if (data) {
      setSyncInventoryForm(data.syncInventoryForm);
    }
  }, [setSyncInventoryForm, data]);

  return {
    setCacheInventoryUuid: setInventoryUuid,
  };
};

useCurrentSyncInventoryCache.query = gql`
  ${SyncInventoryForm.sync.fragment}

  query useCurrentSyncInventoryCache($inventoryUuid: String!) {
    syncInventoryForm(inventoryUuid: $inventoryUuid) @client {
      ...SyncInventoryForm_sync
    }
  }
`;

const useCurrentSyncInventoryLoad = ({setSyncInventoryForm}: any) => {
  const [inventoryUuid, setInventoryUuid] = useState(null);
  const {loading, data} = useQuery(useCurrentSyncInventoryLoad.query, {
    fetchPolicy: 'network-only',
    skip: !inventoryUuid,
    variables: {inventoryUuid},
  });

  useEffect(() => {
    if (!loading && data) {
      const syncInventoryForm = SyncInventoryForm.load(data.inventory);
      setSyncInventoryForm(SyncInventoryForm.toForm(syncInventoryForm));
    }
  }, [setSyncInventoryForm, loading, data]);

  return {
    loading,
    setLoadInventoryUuid: setInventoryUuid,
  };
};

useCurrentSyncInventoryLoad.query = gql`
  ${SyncInventoryForm.load.fragment}

  query useCurrentSyncInventoryLoad($inventoryUuid: String!) {
    ${gql.query}
    inventory(uuid: $inventoryUuid) {
      id
      ...SyncInventoryForm_load
    }
  }
`;

const writeToCache = ({syncInventoryForm}: any) => {
  client.writeQuery({
    query: useCurrentSyncInventoryCache.query,
    data: {
      __typename: 'SyncInventoryForm',
      syncInventoryForm: SyncInventoryForm.sync(syncInventoryForm),
    },
    variables: {
      inventoryUuid: syncInventoryForm.uuid,
    },
  });
};

const CurrentSyncInventoryFormProvider = ({children}: any) => {
  const form = useForm({
    initialValues: {
      syncInventoryForm: SyncInventoryForm.toForm(SyncInventoryForm.new()),
    },
    onSubmit: ({syncInventoryForm}) => {
      setTimeout(() => writeToCache({syncInventoryForm}), 0);
    },
  });

  const {setFieldValue} = form;
  const setSyncInventoryForm = useCallback(
    (syncInventoryForm) => {
      setFieldValue('syncInventoryForm', syncInventoryForm);
    },
    [setFieldValue],
  );

  const {setCacheInventoryUuid} = useCurrentSyncInventoryCache({setSyncInventoryForm});
  const {setLoadInventoryUuid, loading} = useCurrentSyncInventoryLoad({setSyncInventoryForm});

  // Context for managing UI
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const context = {
    form,
    loading,
    setCacheInventoryUuid,
    setLoadInventoryUuid,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    hasSavedChanges,
    setHasSavedChanges,
    isSubmitting,
    setIsSubmitting,
  };

  return (
    // @ts-expect-error TS(2322): Type '{ form: FormikFormType<{ syncInventoryForm: ... Remove this comment to see the full error message
    <CurrentSyncInventoryFormContext.Provider value={context}>
      {children}
    </CurrentSyncInventoryFormContext.Provider>
  );
};

export default CurrentSyncInventoryFormProvider;
