/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useIsFocused, useMountEffect, useNavigation, useQuery} from '@supermove/hooks';
import {Location, Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import LoadingModal from 'modules/App/components/LoadingModal';
import useCurrentSyncInventoryForm from 'modules/App/hooks/useCurrentSyncInventoryForm';
import SyncInventoryCounts from 'modules/Inventory/components/SyncInventoryCounts';
import JobSidebar from 'modules/Job/components/JobSidebar';

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-horizontal: 20px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const HeaderTitle = Styled.H5`
  flex: 1;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const ShowJobHeader = ({job, syncInventoryForm}: any) => {
  return (
    <Header>
      <HeaderTitle numberOfLines={1}>{Job.getFullName(job)}</HeaderTitle>
      <SyncInventoryCounts syncInventoryForm={syncInventoryForm} />
    </Header>
  );
};

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View`
  flex: 1;
  background-color: ${colors.gray.border};
`;

const Sections = Styled.View`
  margin: 20px;
  background-color: ${colors.white};
  border-radius: 5px;
`;

const Section = Styled.View`
  padding-horizontal: 20px;
`;

const Title = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Name = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const LinkIconContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const LinkIconText = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Empty = Styled.H8`
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const LinkIcon = ({source, empty, text}: any) => {
  return (
    <LinkIconContainer>
      <Icon color={colors.gray.primary} size={14} source={source} />
      <Space width={10} />
      {text ? <LinkIconText>{text}</LinkIconText> : <Empty>{empty}</Empty>}
    </LinkIconContainer>
  );
};

const LocationContainer = Styled.View`
  width: 320px;
  border-radius: 2px;
  padding: 10px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const LocationName = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Tags = Styled.View`
  flex-direction: row;
`;

const Description = Styled.H8`
  color: ${colors.gray.tertiary};
`;

const TagContainer = Styled.View`
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 90px;
  background-color: ${(props) => colors.alpha((props as any).color, 0.1)};
  border-radius: 2px;
`;

const TagText = Styled.H8`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
  text-transform: uppercase;
`;

const Tag = ({color, text}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TagContainer color={color}>
      <TagText color={color}>{text}</TagText>
    </TagContainer>
  );
};

const getName = ({isFirst, isLast, index}: any) => {
  if (isFirst) {
    return 'Pick Up';
  } else if (isLast) {
    return 'Drop Off';
  } else {
    return `Stop ${index}`;
  }
};

const getDescription = ({location}: any) => {
  if (!location.floorNumber || !location.stairCount) {
    return '';
  }

  return `${location.floorNumber} floor, ${location.stairCount} steps`;
};

const LocationSection = ({isFirst, isLast, index, location}: any) => {
  const name = getName({isFirst, isLast, index});

  return (
    <LocationContainer>
      <Row>
        <LocationName>{name}</LocationName>
        <Tags>
          {location.hasLongWalk && <Tag color={colors.red.warning} text={'Long Walk'} />}
          <Space width={10} />
          {location.hasElevator && <Tag color={colors.blue.interactive} text={'Elevator'} />}
        </Tags>
      </Row>
      <Space height={10} />
      <LinkIcon
        source={Icon.MapMarker}
        empty={'Unknown Location'}
        text={Location.getDisplayLocation(location)}
      />
      <Description>{getDescription({location})}</Description>
    </LocationContainer>
  );
};

const ShowJobPageContent = ({job}: any) => {
  const inventoryUuid = job.inventory.uuid;
  const {
    form,
    loading,
    setLoadInventoryUuid,
    setHasUnsavedChanges,
    setHasSavedChanges,
    setIsSubmitting,
  } = useCurrentSyncInventoryForm({inventoryUuid});
  const {syncInventoryForm} = form.values;

  // If we don't have an inventory loaded OR we're looking at a new one, then we need to make a
  // request to load from the server.
  useMountEffect(() => {
    if (!syncInventoryForm.inventoryId || syncInventoryForm.uuid !== inventoryUuid) {
      setLoadInventoryUuid(inventoryUuid);
    }

    // Reset context for UI managment
    setHasUnsavedChanges(false);
    setHasSavedChanges(false);
    setIsSubmitting(false);
  });

  return (
    <Content>
      <ShowJobHeader job={job} syncInventoryForm={syncInventoryForm} />
      <ScrollView style={{flex: 1}}>
        <Sections>
          <Section>
            <Space height={10} />
            <Title>General Info</Title>
            <Space height={10} />
          </Section>
          <Line />
          <Section>
            <Space height={20} />
            <Title>Pick Up Address</Title>
            <Space height={10} />
            <LinkIcon
              source={Icon.MapMarker}
              empty={'Unknown Location'}
              text={Location.getDisplayLocation(job.startLocation)}
            />
          </Section>
          <Section>
            <Space height={20} />
            <Title>Office Notes</Title>
            <Space height={10} />
            {job.officeNotes ? <Text>{job.officeNotes}</Text> : <Empty>None</Empty>}
            <Space height={20} />
          </Section>
          <Line />
          <Section>
            <Space height={20} />
            <Title>Contacts</Title>
            <Space height={10} />
            <Name>{job.customer.fullName}</Name>
            <Space height={10} />
            <LinkIcon source={Icon.Envelope} empty={'Not Specified'} text={job.customer.email} />
            <Space height={10} />
            <LinkIcon
              source={Icon.Phone}
              empty={'Not Specified'}
              text={Phone.display(job.customer.phoneNumber)}
            />
            <Space height={20} />
          </Section>
          <Line />
          <Section>
            <Space height={20} />
            <Title>Stops</Title>
            <Space height={20} />
            {job.locations.map((location: any, index: any) => (
              <React.Fragment key={location.id}>
                <LocationSection
                  isFirst={index === 0}
                  isLast={index === job.locations.length - 1}
                  index={index}
                  location={location}
                />
                <Space height={20} />
              </React.Fragment>
            ))}
            <Space height={20} />
          </Section>
        </Sections>
      </ScrollView>
      <LoadingModal loading={loading} title={'Downloading updates...'} />
    </Content>
  );
};

const ShowJobPage = () => {
  const {params} = useNavigation();
  const {jobUuid} = params;
  const {loading, data, refetch} = useQuery(ShowJobPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid,
    },
  });

  useIsFocused({
    onFocus: () => refetch(),
  });

  return (
    <Container key={jobUuid}>
      <JobSidebar selected={'ShowJob'} jobUuid={jobUuid} />
      <Loading loading={loading}>{() => <ShowJobPageContent job={data.job} />}</Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowJobPage.query = gql`
  ${Job.getFullName.fragment}
  ${Location.getDisplayLocation.fragment}

  query ShowJobPage($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      officeNotes
      customer {
        id
        fullName
        phoneNumber
        email
      }
      startLocation {
        id
        ...Location_getDisplayLocation
      }
      locations {
        id
        hasElevator
        hasLongWalk
        floorNumber
        stairCount
        ...Location_getDisplayLocation
      }
      inventory {
        id
        uuid
      }
      ...Job_getFullName
    }
  }
`;

export default ShowJobPage;
