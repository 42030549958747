// Libraries
import React from 'react';

// Supermove
import {ExternalLink, ScrollView, Space, Styled} from '@supermove/components';
import {useModal, useMountEffect, useNavigation, useResponsive, useState} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import useLoginForm from '@shared/modules/Authentication/hooks/useLoginForm';
import LOCAL_STORAGE from 'core/constants/LOCAL_STORAGE';
import Field from 'modules/App/components/Field';

const Row = Styled.View`
  flex-direction: row;
`;

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
`;

const Logo = Styled.H1`
  ${fontWeight(700)}
  margin-top: 40px;
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  margin-top: 20px;
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  width: 120px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrivacyPolicyText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const DeniedPermissionContent = ({setIsDenied}: any) => {
  return (
    <React.Fragment>
      <SmallModal.BoldText>
        {`Supermove is unable to continue without this permission.`}
      </SmallModal.BoldText>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button color={colors.blue.interactive} onPress={() => setIsDenied(false)}>
          Go Back
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const RequestPermissionContent = ({handleAcknowledge, setIsDenied}: any) => {
  return (
    <React.Fragment>
      <SmallModal.BoldText>
        {`In order to facilitate payments, Supermove collects phone number and installed ` +
          `applications data even when the app is not in use. This information is shared ` +
          `with Stripe, our payments processor.`}
      </SmallModal.BoldText>
      <Space height={16} />
      <Row>
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <SmallModal.ButtonText color={colors.blue.interactive}>
            Privacy Policy
          </SmallModal.ButtonText>
        </ExternalLink>
      </Row>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={() => setIsDenied(true)}>Deny</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={handleAcknowledge}>
          Accept
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const AcknowledgeDataCollectionModal = ({isOpen, handleAcknowledge}: any) => {
  const [isDenied, setIsDenied] = useState(false);

  return (
    <SmallModal isOpen={isOpen}>
      {isDenied ? (
        <DeniedPermissionContent setIsDenied={setIsDenied} />
      ) : (
        <RequestPermissionContent handleAcknowledge={handleAcknowledge} setIsDenied={setIsDenied} />
      )}
    </SmallModal>
  );
};

const LoginPage = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const acknowledgeDataCollectionModal = useModal({name: 'Ackowledge Data Collection Modal'});
  const handleCheckAcknowledgedDataCollection = async () => {
    const isAcknowledgedDataCollection = await Storage.getItem(
      LOCAL_STORAGE.DATA_COLLECTION_STATUS,
    );
    if (!isAcknowledgedDataCollection) {
      acknowledgeDataCollectionModal.handleOpen();
    }
  };
  useMountEffect(() => {
    handleCheckAcknowledgedDataCollection();
  });
  const {form, submitting, handleSubmit} = useLoginForm({
    onSuccess: () => {
      navigator.navigate('Home');
    },
  });

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
      <Content {...responsive}>
        <Logo>Supermove</Logo>
        <Title>Welcome back!</Title>
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'email'}
          label={'Email'}
          input={{
            autoCorrect: false,
            autoCapitalize: 'none',
            placeholder: 'Enter your email',
            style: {
              marginTop: 3,
            },
          }}
        />
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'password'}
          label={'Password'}
          input={{
            secureTextEntry: true,
            placeholder: 'Enter your password',
            onSubmitEditing: handleSubmit,
            style: {
              marginTop: 3,
            },
          }}
        />
        <Actions>
          <SubmitButton loading={submitting} onPress={handleSubmit}>
            <SubmitText>Log In</SubmitText>
          </SubmitButton>
        </Actions>
        <Space height={12} />
        <ExternalLink url={`https://supermove.co/privacy-policy`}>
          <PrivacyPolicyText>Privacy Policy</PrivacyPolicyText>
        </ExternalLink>
        <AcknowledgeDataCollectionModal
          isOpen={acknowledgeDataCollectionModal.isOpen}
          handleAcknowledge={() => {
            Storage.setItem(LOCAL_STORAGE.DATA_COLLECTION_STATUS, 'ACKNOWLEDGED');
            acknowledgeDataCollectionModal.handleClose();
          }}
          handleClose={acknowledgeDataCollectionModal.handleClose}
        />
      </Content>
    </ScrollView>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
